


























































































































import useMisc from "@/use/misc";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      headers: [
        {
          value: "index",
          sortable: false,
          text: root.$tc("layout.misc.orderNumber"),
          width: 1,
        },
        {
          value: "name",
          sortable: false,
          text: root.$tc("layout.misc.hotelName"),
        },
        {
          value: "sendRegistrationToEmail",
          sortable: false,
          text: root.$tc("layout.misc.sendRequest"),
          align: "right",
        },
        {
          value: "roomAmount",
          text: root.$tc("layout.misc.rooms"),
          sortable: false,
          width: 1,
          align: "center",
        },
        {
          value: "isEnabled",
          text: root.$tc("layout.misc.status"),
          sortable: false,
          width: 1,
          align: "right",
        },
        {
          value: "actions",
          text: "",
          sortable: false,
          align: "right",
          width: 1,
        },
      ],
      items: [],
      loading: false,
      loaded: false,
      total: 0,
      currency: "",
      options: {
        sortBy: [],
        sortDesc: [],
      },
    });

    const { getDiscountType, getChoiceType } = useMisc({ root });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const { sortBy, sortDesc } = (state.options as unknown) as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/hotel`, {
          params: { sortBy, sortDesc },
        })
        .then(({ data: { hotels, total } }) => {
          state.items = hotels;
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const copyItem = (item: any, room: any) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      const name = prompt(
        root.$tc("panel.globalTools.location.enterNewLocationName"),
        `${room.name} (kopia)`
      );

      const data = {
        name,
        isEnabled: room.isEnabled,
        price: room.price,
        weekendPrice: room.weekendPrice,
        amount: room.amount,
        roommate: room.roommate,
        hotelId: item.id,
        discountsIds: room.discountsIds,
        participantGroupId: room.participantGroupId,
      };
      if (name) {
        axiosInstance
          .post("room", data, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(fetchData)
          .catch((error) => {
            console.log(error);
            alert(root.$tc("panel.globalTools.location.newLocationError"));
          })
          .finally(() => {
            state.loading = false;
          });
      } else {
        state.loading = false;
      }
    };

    const changeRoomStatus = (item: any, room: any) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      state.loading = true;

      const discounts = room.discounts.map(
        (discount: { id: string }) => discount.id
      );

      const data = {
        name: room.name,
        isEnabled: !room.isEnabled,
        price: room.price,
        weekendPrice: room.weekendPrice,
        amount: room.amount,
        roommate: room.roommate,
        hotelId: item.id,
        discountsIds: discounts,
        participantGroupId: room.participantGroup.id,
      };

      axiosInstance
        .put(`/room/${room.id}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          fetchData();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loading = false;
        });
    };
    const changeHotelStatus = (item: any) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      state.loading = true;

      const data = {
        isEnabled: !item.isEnabled,
        registrationFormType: item.registrationFormType,
        registrationFormPayment: item.registrationFormPayment,
        hotelPaymentConfirm: item.hotelPaymentConfirm,
        sendRegistrationToEmail: item.sendRegistrationToEmail,
        email: item.email,
        additionalInformation: item.additionalInformation,
        locationId: item.location.id,
      };

      axiosInstance
        .put(`/hotel/${item.id}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          fetchData();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loading = false;
        });
    };
    // Fetch event

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.currency = event.currency;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const deleteHotel = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`/hotel/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    const deleteRoom = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`/room/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    return {
      state,
      fetchData,
      deleteHotel,
      deleteRoom,
      getDiscountType,
      getChoiceType,
      copyItem,
      changeRoomStatus,
      changeHotelStatus,
    };
  },
});
