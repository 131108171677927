var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.state.loaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):_c('v-container',{staticClass:"o-accomodation-module-list"},[_c('v-data-table',{attrs:{"items":_vm.state.items,"item-key":"id","headers":_vm.state.headers,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"expanded":_vm.state.items,"hide-default-footer":"","item-class":function (item) { return 'blue-grey lighten-4'; },"items-per-page":-1},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.state.items.indexOf(item) + 1))]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v(_vm._s(item.location.name))])]}},{key:"item.sendRegistrationToEmail",fn:function(ref){
var item = ref.item;
return [(item.sendRegistrationToEmail)?_c('span',[_c('span',[_vm._v(_vm._s(item.email))])]):_c('span',[_vm._v("---")])]}},{key:"item.isEnabled",fn:function(ref){
var item = ref.item;
return [(item.isEnabled)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.roomAmount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.rooms.length))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[(item.rooms && item.rooms.length)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('layout.misc.name')))]),_c('th',[_vm._v(_vm._s(_vm.$t('layout.misc.price')))]),_c('th',[_vm._v(_vm._s(_vm.$t('layout.misc.weekend')))]),_c('th',[_vm._v(_vm._s(_vm.$t('layout.misc.group')))]),_c('th',[_vm._v(_vm._s(_vm.$t('layout.misc.quantity')))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.$t('layout.misc.status')))]),_c('th',{staticClass:"text-right",staticStyle:{"width":"40px"}})])]),_c('tbody',_vm._l((item.rooms),function(room){return _c('tr',{key:room.id},[_c('td',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(room.name))]),_c('span',[_vm._v(_vm._s(room.englishName))])]),_c('td',[_vm._v(_vm._s(room.price)+" "+_vm._s(_vm.state.currency))]),_c('td',[_vm._v(_vm._s(room.weekendPrice)+" "+_vm._s(_vm.state.currency))]),_c('td',[_vm._v(_vm._s(room.participantGroup.name))]),_c('td',[_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('layout.misc.reserved'))+":")]),_c('span',[_vm._v(_vm._s(room.numberOfParticipants))])]),_c('span',[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('layout.misc.left'))+":")]),(room.amount || room.amount === 0)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(room.amount))]):_c('span',{staticClass:"font-weight-bold"},[_vm._v("---")])])])]),_c('td',{staticClass:"text-right"},[(room.isEnabled)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1),_c('td',{staticClass:"text-right"},[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","x-small":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.changeRoomStatus(item,room)}}},[(!room.isEnabled)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")])],1):_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('layout.misc.status')))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.copyItem(item,room)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('layout.misc.copy')))])],1),_c('v-list-item',{attrs:{"to":{ name: 'panel.event.view.accommodationModule.editRoom', params: { arid: room.id } }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('layout.misc.edit')))])],1),_c('v-dialog',{attrs:{"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)]}}],null,true),model:{value:(room.deleteDialog),callback:function ($$v) {_vm.$set(room, "deleteDialog", $$v)},expression:"room.deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('layout.misc.areYouSure')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){room.deleteDialog = false}}},[_vm._v(_vm._s(_vm.$t('layout.misc.cancel')))]),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"red"},on:{"click":function($event){return _vm.deleteRoom(room)}}},[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)],1)],1)],1)],1)],1)])}),0)]},proxy:true}],null,true)}):_c('span',[_vm._v(_vm._s(_vm.$t('panel.event.modules.accommodationModule.list.noRoomAdded')))])],1)],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","x-small":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.changeHotelStatus(item)}}},[(!item.isEnabled)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check")])],1):_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('layout.misc.status')))])],1),_c('v-list-item',{attrs:{"to":{ name: 'panel.event.view.accommodationModule.editHotel', params: { ahid: item.id } }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.edit')))])],1),_c('v-dialog',{attrs:{"max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)]}}],null,true),model:{value:(item.deleteDialog),callback:function ($$v) {_vm.$set(item, "deleteDialog", $$v)},expression:"item.deleteDialog"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-4",attrs:{"color":"red"}},[_vm._v("mdi-alert-circle-outline")]),_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.areYouSure')))])],1),_c('v-card-subtitle',{staticClass:"mt-4 font-weight-bold text-uppercase"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.$t('panel.event.modules.accommodationModule.list.deleteHotelWarning')))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){item.deleteDialog = false}}},[_vm._v(_vm._s(_vm.$t('layout.misc.cancel')))]),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"red"},on:{"click":function($event){return _vm.deleteHotel(item)}}},[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }